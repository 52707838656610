import styles from './index.module.css';
import ExternalLink from 'components/core/external-link';

import DiscordLogo from 'assets/images/discord.svg';
import TelegramLogo from 'assets/images/telegram.svg';
import FacebookLogo from 'assets/images/facebook.svg';
import LinkedInLogo from 'assets/images/linkedin.svg';
import TwitterLogo from 'assets/images/twitter.svg';
import MediumLogo from 'assets/images/medium.svg';
import RedditLogo from 'assets/images/reddit.svg';
import YoutubeLogo from 'assets/images/youtube.svg';

import { URLS } from 'assets/config';
import { ROUTES } from 'routes';

import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

const Footer = () => {
  const intl = useIntl();
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  const socials = useMemo(
    () => [
      { icon: DiscordLogo, url: URLS.broxus.discord },
      { icon: TelegramLogo, url: URLS.broxus.telegram },
      { icon: FacebookLogo, url: URLS.broxus.facebook },
      { icon: LinkedInLogo, url: URLS.broxus.linkedIn },
      { icon: TwitterLogo, url: URLS.broxus.twitter },
      { icon: MediumLogo, url: URLS.broxus.medium },
      { icon: RedditLogo, url: URLS.broxus.reddit },
      { icon: YoutubeLogo, url: URLS.broxus.youTube },
    ],
    []
  );

  return (
    <footer className={styles.main}>
      <div className={styles.body}>
        <div className={styles.legal}>
          <span>© Broxus, {currentYear}</span>

          <div className={styles.legal_links}>
            <Link to={ROUTES.legal.termsOfUse.fullPath}>
              {intl.formatMessage({
                id: 'footer.terms_of_use',
                defaultMessage: 'Terms of use',
              })}
            </Link>

            <Link to={ROUTES.legal.privacyPolicy.fullPath}>
              {intl.formatMessage({
                id: 'footer.privacy_policy',
                defaultMessage: 'Privacy policy',
              })}
            </Link>

            {/* <Link to={ROUTES.legal.cookies.fullPath}>
              {intl.formatMessage({
                id: 'footer.cookies',
                defaultMessage: 'Cookies',
              })}
            </Link> */}
          </div>
        </div>

        <div className={styles.socials}>
          {socials.map((x) => (
            <ExternalLink key={x.url} href={x.url}>
              <img src={x.icon} width={20} height={20} alt='' />
            </ExternalLink>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
