import styles from './index.module.css';
import Button from '../button';
import ExternalLink from '../external-link';

import { URLS } from 'assets/config';
import { useIntl } from 'react-intl';
import { useCallback, useMemo } from 'react';
import { useWalletStore } from 'providers/BaseStoresProvider';
import { observer } from 'mobx-react-lite';
import { isAndroid, isFirefox, isIOS } from 'react-device-detect';
import { FaAndroid, FaApple, FaChrome, FaFirefox } from 'react-icons/fa6';

const ConnectWalletButton = observer(() => {
  const intl = useIntl();
  const walletStore = useWalletStore();

  const onConnect = useCallback(() => {
    walletStore.connect();
  }, [walletStore]);

  const data = useMemo(() => {
    if (isFirefox) {
      return {
        url: URLS.everWallet.firefox,
        icon: <FaFirefox />,
        text: intl.formatMessage({
          id: 'ever_wallet.install.firefox',
          defaultMessage: 'Install EVER Wallet Firefox Extension',
        }),
      };
    }

    if (isAndroid) {
      return {
        url: URLS.everWallet.android,
        icon: <FaAndroid />,
        text: intl.formatMessage({
          id: 'ever_wallet.install.android',
          defaultMessage: 'Install EVER Wallet for Android',
        }),
      };
    }

    if (isIOS) {
      return {
        url: URLS.everWallet.ios,
        icon: <FaApple />,
        text: intl.formatMessage({
          id: 'ever_wallet.install.ios',
          defaultMessage: 'Install EVER Wallet for iOS',
        }),
      };
    }

    return {
      url: URLS.everWallet.chrome,
      icon: <FaChrome />,
      text: intl.formatMessage({
        id: 'ever_wallet.install.chrome',
        defaultMessage: 'Install EVER Wallet Chrome Extension',
      }),
    };
  }, [intl]);

  if (walletStore.extensionInstalled) {
    return (
      <Button variant='primary' className={styles.btn} onClick={onConnect}>
        {data.icon}

        <span>
          {intl.formatMessage({
            id: 'ever_wallet.connect',
            defaultMessage: 'Connect EVER Wallet',
          })}
        </span>
      </Button>
    );
  }

  return (
    <ExternalLink href={data.url}>
      <Button variant='primary' className={styles.btn}>
        {data.icon}

        <span>{data.text}</span>
      </Button>
    </ExternalLink>
  );
});

export default ConnectWalletButton;
