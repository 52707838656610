import BigNumber from 'bignumber.js';

import { URLS } from 'assets/config';
import { v4 as uuidv4 } from 'uuid';
import { makeAutoObservable, runInAction } from 'mobx';
import { Address, ProviderRpcClient } from 'everscale-inpage-provider';

type EverAccount = {
  address: string;
  publicKey: string;
  walletType: string;
};

const COIN_DECIMALS = 9;
const COIN_BALANCE_FETCH_INTERVAL_SEC = 15;

export class WalletStore {
  constructor() {
    this._everProvider = new ProviderRpcClient();

    makeAutoObservable(this, {}, { autoBind: true });
  }

  private _userId = uuidv4();
  private _everProvider: ProviderRpcClient;
  private _extensionInstalled: boolean = false;
  private _initialized: boolean = false;
  private _account: EverAccount | null = null;
  private _balanceInterval: NodeJS.Timeout | null = null;
  private _coinBalance = BigNumber(0);

  get userId() {
    return this._userId;
  }

  get initialized() {
    return this._initialized;
  }

  get account() {
    return this._account;
  }

  get extensionInstalled() {
    return this._extensionInstalled;
  }

  get coinBalance() {
    return this._coinBalance;
  }

  async init() {
    try {
      await this._everProvider.ensureInitialized();
      await this.updateAccountState();

      runInAction(() => {
        this._extensionInstalled = true;
        this._initialized = true;
      });
    } catch {
      runInAction(() => {
        this._extensionInstalled = false;
        this._initialized = true;
      });
    }
  }

  async connect() {
    if (!this.extensionInstalled) {
      window.location.href = URLS.everWallet.landing;
      return;
    }

    try {
      await this._everProvider.requestPermissions({
        permissions: ['basic', 'accountInteraction'],
      });

      await this.updateAccountState();
    } catch {
      await this.disconnect();
    }
  }

  async disconnect() {
    await this._everProvider.disconnect();
    await this.updateAccountState();
  }

  private async updateAccountState() {
    const state = await this._everProvider.getProviderState();

    const accountInteraction = state.permissions.accountInteraction;

    let account: EverAccount | null = null;

    if (accountInteraction) {
      account = {
        address: accountInteraction.address.toString(),
        publicKey: accountInteraction.publicKey,
        walletType: accountInteraction.contractType,
      };
    }

    if (!!this._balanceInterval) {
      clearInterval(this._balanceInterval);
    }

    const balanceInterval = account
      ? setInterval(async () => {
          const coinBalance = await this.getCoinBalance(
            this._everProvider,
            account?.address
          );

          runInAction(() => {
            this._coinBalance = coinBalance;
          });
        }, COIN_BALANCE_FETCH_INTERVAL_SEC * 1000)
      : null;

    const coinBalance = await this.getCoinBalance(
      this._everProvider,
      account?.address
    );

    runInAction(() => {
      runInAction(() => {
        this._account = account;
        this._balanceInterval = balanceInterval;
        this._coinBalance = coinBalance;
      });
    });
  }

  private async getCoinBalance(
    provider: ProviderRpcClient | null,
    address: string | undefined
  ) {
    if (!provider || !address) {
      return BigNumber(0);
    }

    const balance = await provider.getBalance(new Address(address));

    return BigNumber(balance).shiftedBy(-COIN_DECIMALS);
  }
}
