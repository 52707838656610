import styles from './index.module.css';
import classNames from 'classnames';

import { NavLink } from 'react-router-dom';

type Tab = {
  id: string;
  text: string;
  navLink?: string;
};

const TabNavLink = ({ tab }: { tab: Tab }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        classNames(styles.link, { [styles.active]: isActive })
      }
      to={tab.navLink ?? ''}
      replace
    >
      {tab.text}
    </NavLink>
  );
};

type ButtonProps = {
  tab: Tab;
  activeTabId?: string;
  onTabChanged?: (tab: Tab) => void;
};
const TabButton = ({ tab, activeTabId, onTabChanged }: ButtonProps) => {
  return (
    <button
      className={classNames(styles.link, {
        [styles.active]: tab.id === activeTabId,
      })}
      onClick={() => {
        if (tab.id === activeTabId) {
          return;
        }

        onTabChanged?.(tab);
      }}
    >
      {tab.text}
    </button>
  );
};

type Props = {
  data: Tab[];
  className?: string;
  activeTabId?: string;
  onTabChanged?: (tab: Tab) => void;
};
const Tabs = ({ data, className, activeTabId, onTabChanged }: Props) => {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.body}>
        {data.map((tab) =>
          tab.navLink != null ? (
            <TabNavLink key={tab.id} tab={tab} />
          ) : (
            <TabButton
              key={tab.id}
              tab={tab}
              activeTabId={activeTabId}
              onTabChanged={onTabChanged}
            />
          )
        )}
      </div>
    </div>
  );
};

export default Tabs;
