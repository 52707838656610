import styles from './index.module.css';

import TermsOfUse from './terms-of-use';
import PrivacyPolicy from './privacy-policy';

import { ROUTES } from 'routes';
import { Route, Navigate, Routes } from 'react-router-dom';

const LegalPage = () => {
  return (
    <div className={styles.main}>
      <div className={styles.body}>
        <Routes>
          <Route path={ROUTES.legal.termsOfUse.path} element={<TermsOfUse />} />
          <Route
            path={ROUTES.legal.privacyPolicy.path}
            element={<PrivacyPolicy />}
          />
          {/* <Route
            path={ROUTES.legal.cookies.path}
            element={<div>cookies</div>}
          /> */}
          <Route
            path='*'
            element={<Navigate to={ROUTES.legal.termsOfUse.fullPath} replace />}
          />
        </Routes>
      </div>
    </div>
  );
};

export default LegalPage;
