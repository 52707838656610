const PrivacyPolicy = () => {
  return (
    <div className='flex flex-col gap-8'>
      <span className='text-3xl text-center font-medium'>
        EVER Wallet Privacy policy
      </span>
      <span>
        This Policy explains how your personal data may be processed when you
        use the EVER Wallet browser extension and/or mobile application
        (hereinafter - the Wallet). It does not address Finstream OU’s other
        personal data processing operations, for example where we provide
        services to you on the basis of a contract between us. Additional
        policies and procedures addressing these processes are in place and we
        provide information to data subjects at the point of data collection or
        subsequently, as required by law.
      </span>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          Data controller
        </span>
        <span>
          The Wallet is developed and operated by Finstream OU, a company
          registered under the legislation of the Republic of Estonia at the
          address Harjumaa, Tallinn, Lasnamae linnaosa, Katusepapi tn. 4/2,
          11412, Estonia. Contact us via email at dpo@broxus.com with any
          questions regarding this Policy or any other data protection issue
          related to the Wallet.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          What we (do not) collect
        </span>
        <span>
          In line with our mission and values, we have built the Wallet so that
          we do not collect any data from users for any purpose. Our only
          interest is maintaining the security of the Wallet, therefore:
        </span>

        <div className='flex flex-col gap-1'>
          <span>• we do not set any cookies;</span>
          <span>
            • we do not collect any data whatsoever about people who use the
            Wallet;
          </span>
          <span>
            • we do not conduct any analysis of how people use the Wallet;
          </span>
          <span>
            • we do not allow third-party tracking of any kind on the Wallet.
          </span>
        </div>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          Third-party data processing and retention
        </span>
        <span>
          Due to the nature of the Wallet, we use the facilities of Google
          Chrome Store, Google Play Store, and Apple Store (hereinafter - the
          Stores) to distribute the Wallet to our users. Although we do not
          provide any user data to the Stores, they may collect some information
          about you, as stipulated in their Privacy Policies.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          Information security
        </span>
        <span>
          As no user data is collected at our servers, the Wallet stores such
          information as seed phrases and private keys at your device in an
          encrypted form. The access to these data is controlled solely by you
          by setting the password for encryption and/or using the biometry
          features of your device (subject to the Privacy Policy of the device
          manufacturer).
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>Your rights</span>
        <span>
          Where Finstream OU processes your personal data you may have the
          right:
        </span>
        <div className='flex flex-col'>
          <span>• to be informed as to whether AWO holds data about you;</span>
          <span>• to access that data;</span>
          <span>• to have inaccurate data corrected;</span>
          <span>• to have your data deleted;</span>
          <span>• to opt-out of particular data processing operations;</span>
          <span>
            • to receive your data in a form that makes it “portable”;
          </span>
          <span>• to object to data processing;</span>
          <span>
            • to receive an explanation about any automated decision making
            and/or profiling, and to challenge those decisions where
            appropriate.
          </span>
        </div>
        <span>
          You can seek to exercise these rights via email to dpo@broxus.com. You
          also have the right to lodge concerns or complaints with the Estonia
          Information Commissioner’s Office. Data subjects covered by EU law may
          also be entitled to lodge complaints with the data protection
          supervisory authority in their country of residence.
        </span>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
