import localJson from './local.json';
import stageJson from './stage.json';
import prodJson from './prod.json';
import commonJson from './common.json';

function getConfig() {
  switch (process.env.REACT_APP_ENV) {
    case 'STAGE':
      return stageJson;

    case 'PROD':
      return prodJson;

    default:
      return localJson;
  }
}

const currentConfig = getConfig();

export const ORIGIN_URL = currentConfig.originUrl;

export const SIMPLEX_API_URL = currentConfig.simplexApiUrl;

export const URLS = commonJson.urls;

export const MANIFEST_URL = commonJson.buyEverAssetsManifestUrl;
