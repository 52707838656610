import {
  Api,
  CreatePaymentRequestDto,
  GetQuoteRequestDto,
} from 'api/simplex-api';
import { CurrencyModel } from 'models/CurrencyModel';
import { PaymentModel } from 'models/PaymentModel';
import { QuoteModel } from 'models/QuoteModel';

export async function getSupportedFiatCurrencies(simplexApi: Api<unknown>) {
  const supportedCurrencies =
    await simplexApi.api.apiControllerGetSupportedFiatCurrencies();

  const models = supportedCurrencies.data.currencies.map(
    (x) => new CurrencyModel(x)
  );

  return models;
}

export async function getQuote(
  props: GetQuoteRequestDto,
  simplexApi: Api<unknown>
) {
  const response = await simplexApi.api.apiControllerGetQuote(props);

  return {
    model: response.data.quote
      ? new QuoteModel(response.data.quote)
      : undefined,
    error_message: response.data.errorMessage,
  };
}

export async function createPayment(
  props: CreatePaymentRequestDto,
  simplexApi: Api<unknown>
) {
  const response = await simplexApi.api.apiControllerCreatePayment(props);

  const model = new PaymentModel(response.data.payment);

  return model;
}
