import styles from './index.module.css';

import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { ROUTES } from 'routes';

import { useWalletStore } from 'providers/BaseStoresProvider';
import { cutAddress } from 'utils/strings';
import { bigNumberToStr } from 'utils/bignumber';

import { ReactComponent as EverLogo } from 'assets/images/ever.svg';
import { ReactComponent as EverscaleLogo } from 'assets/images/everscale.svg';
import { ReactComponent as ExitSvg } from 'assets/images/exit.svg';
import Button from 'components/core/button';
import { useCallback } from 'react';

const Header = observer(() => {
  const intl = useIntl();
  const wallet = useWalletStore();

  const onConnect = useCallback(() => {
    wallet.connect();
  }, [wallet]);

  const onDisconnect = useCallback(() => {
    wallet.disconnect();
  }, [wallet]);

  return (
    <header className={styles.main}>
      <Link to={ROUTES.index.fullPath}>
        <EverscaleLogo className={styles.logo} />
      </Link>

      <div className={styles.wallet_container}>
        <EverLogo className={styles.ever_logo} />

        <div className={styles.account_info}>
          <span className={styles.account}>
            {wallet.account ? cutAddress(wallet.account.address) : 'Everscale'}
          </span>
          <span className={styles.balance}>
            {wallet.account
              ? `${bigNumberToStr(wallet.coinBalance, 4)} EVER`
              : intl.formatMessage({
                  id: 'header.not_connected',
                  defaultMessage: 'Not connected',
                })}
          </span>
        </div>

        {!wallet.account ? (
          <Button
            className={styles.connect_btn}
            variant='secondary'
            onClick={onConnect}
          >
            {intl.formatMessage({
              id: 'header.connect',
              defaultMessage: 'Connect',
            })}
          </Button>
        ) : (
          <button className={styles.disconnect_btn} onClick={onDisconnect}>
            <ExitSvg />
          </button>
        )}
      </div>
    </header>
  );
});

export default Header;
