import { CurrencyDto } from 'api/simplex-api';

export class CurrencyModel {
  constructor(dto: CurrencyDto) {
    this.symbol = dto.symbol;
    this.minAmount = dto.minAmount;
    this.maxAmount = dto.maxAmount;
  }

  symbol: string;
  minAmount: number;
  maxAmount: number;
}
