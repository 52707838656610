import { Api } from 'api/simplex-api';
import { SIMPLEX_API_URL } from 'assets/config';
import { observer, useLocalObservable } from 'mobx-react-lite';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IntlProvider } from 'react-intl';
import { BrowserRouter } from 'react-router-dom';
import { LanguageStore } from 'stores/LanguageStore';
import { StaticDataStore } from 'stores/StaticDataStore';
import { WalletStore } from 'stores/WalletStore';

type BaseStores = {
  languageStore: LanguageStore;
  walletStore: WalletStore;
  staticDataStore: StaticDataStore;
  simplexApi: Api<unknown>;
};

const baseStoreContext = createContext<BaseStores>({} as BaseStores);

export const ProvideBaseStores = observer(
  ({ children }: { children: ReactNode }) => {
    const [initialized, setInitialized] = useState(false);

    const simplexApi = useLocalObservable(
      () => new Api({ baseURL: SIMPLEX_API_URL }),
      []
    );
    const walletStore = useLocalObservable(() => new WalletStore(), []);
    const languageStore = useLocalObservable(() => new LanguageStore(), []);
    const staticDataStore = useLocalObservable(() => new StaticDataStore(), []);

    const stores = useMemo(
      () => ({
        walletStore,
        languageStore,
        staticDataStore,
        simplexApi,
      }),
      [languageStore, simplexApi, staticDataStore, walletStore]
    );

    useEffect(() => {
      const init = async () => {
        languageStore.init();

        await Promise.all([staticDataStore.init(), walletStore.init()]);

        setInitialized(true);
      };

      init();

      return () => {};
    }, [languageStore, staticDataStore, walletStore]);

    if (!initialized) {
      return null;
    }

    return (
      <baseStoreContext.Provider value={stores}>
        <IntlProvider
          defaultLocale={languageStore.language.id}
          locale={languageStore.language.id}
          messages={languageStore.messages}
        >
          <BrowserRouter>{children}</BrowserRouter>
        </IntlProvider>
      </baseStoreContext.Provider>
    );
  }
);

export const useSimplexApi = () => {
  const { simplexApi } = useContext(baseStoreContext);

  return simplexApi;
};

export const useWalletStore = () => {
  const { walletStore } = useContext(baseStoreContext);

  return walletStore;
};

export const useLanguageStore = () => {
  const { languageStore } = useContext(baseStoreContext);

  return languageStore;
};

export const useStaticDataStore = () => {
  const { staticDataStore } = useContext(baseStoreContext);

  return staticDataStore;
};
