import styles from './index.module.css';

const PageContainer = ({
  title,
  children,
}: {
  title?: string;
  children?: React.ReactNode;
}) => {
  return (
    <div className={styles.main}>
      {!!title && <div className={styles.title}>{title}</div>}

      {children}
    </div>
  );
};

export default PageContainer;
