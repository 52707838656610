import SimplexPurchaseForm from './purchase-form';
import PageContainer from 'components/layout/page-container';

import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { ProvideSimplexPageStore } from './provider/SimplexPageStoreProvider';

const SimplexPage = observer(() => {
  const intl = useIntl();

  return (
    <ProvideSimplexPageStore>
      <PageContainer
        title={intl.formatMessage({
          id: 'simplex_page.title',
          defaultMessage: 'Top up EVER Wallet',
        })}
      >
        <SimplexPurchaseForm />
      </PageContainer>
    </ProvideSimplexPageStore>
  );
});

export default SimplexPage;
