import styles from './index.module.css';
import classNames from 'classnames';

import { ReactComponent as ArrowDown } from 'assets/images/arrow-down.svg';

import {
  FocusableItem,
  Menu,
  MenuChangeEvent,
  MenuItem,
} from '@szhsin/react-menu';

import { CurrencyModel } from 'models/CurrencyModel';
import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

type Props = {
  currentSelection?: CurrencyModel | null;
  availableCurrencies?: CurrencyModel[] | null;
  onSelect?: (currency: CurrencyModel) => void;
};

const CurrencySelect = ({
  currentSelection,
  availableCurrencies,
  onSelect,
}: Props) => {
  const intl = useIntl();
  const [filter, setFilter] = useState('');

  const filteredItems = useMemo(
    () =>
      availableCurrencies?.filter((x) =>
        x.symbol.toUpperCase().includes(filter.trim().toUpperCase())
      ),
    [availableCurrencies, filter]
  );

  const onMenuChange = useCallback((event: MenuChangeEvent) => {
    if (!event.open) {
      return;
    }

    setFilter('');
  }, []);

  return (
    <Menu
      onMenuChange={onMenuChange}
      menuButton={({ open }) => (
        <div className={styles.btn_container}>
          <span>{currentSelection?.symbol}</span>

          <ArrowDown
            className={classNames(styles.btn_container_arrow, {
              [styles.open]: open === true,
            })}
          />
        </div>
      )}
      menuClassName={styles.menu}
      align='center'
      overflow='auto'
      boundingBoxPadding='24px 12px'
      gap={10}
    >
      <FocusableItem className={styles.search_input_container}>
        {({ ref }) => (
          <input
            ref={ref}
            className={styles.search_input}
            type='text'
            placeholder={intl.formatMessage({
              id: 'select.currency.search',
              defaultMessage: 'Search...',
            })}
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />
        )}
      </FocusableItem>

      {filteredItems?.map((x) => (
        <MenuItem
          key={x.symbol}
          className={classNames(styles.menu_item, {
            [styles.menu_item_selected]: currentSelection?.symbol === x.symbol,
          })}
          onClick={() => onSelect?.(x)}
        >
          {x.symbol}
        </MenuItem>
      ))}

      {filteredItems?.length === 0 && (
        <div className={styles.no_options}>
          {intl.formatMessage({
            id: 'select.currency.no_items',
            defaultMessage: 'No options',
          })}
        </div>
      )}
    </Menu>
  );
};

export default CurrencySelect;
