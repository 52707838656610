import styles from './index.module.css';

import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

const Wrapper = ({ children }: { children?: React.ReactNode }) => {
  const location = useLocation();

  useLayoutEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [location.pathname]);

  return <div className={styles.main}>{children}</div>;
};

export default Wrapper;
