import { PaymentDto } from 'api/simplex-api';

export class PaymentModel {
  constructor(dto: PaymentDto) {
    this.paymentId = dto.paymentId;
    this.checkoutUrl = dto.checkoutUrl;
    this.version = dto.version;
    this.flowType = dto.flowType;
    this.partner = dto.partner;
  }

  paymentId: string;
  checkoutUrl: string;
  version: string;
  flowType: string;
  partner: string;
}
