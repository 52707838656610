import { QuoteDto } from 'api/simplex-api';

export class QuoteModel {
  constructor(dto: QuoteDto) {
    this.userId = dto.userId;
    this.quoteId = dto.quoteId;
    this.digitalCurrency = dto.digitalCurrency;
    this.digitalCurrencyAmount = dto.digitalCurrencyAmount;
    this.fiatCurrency = dto.fiatCurrency;
    this.fiatCurrencyAmount = dto.fiatCurrencyAmount;
    this.validUntil = dto.validUntil;
  }

  userId: string;
  quoteId: string;
  digitalCurrency: string;
  digitalCurrencyAmount: number;
  fiatCurrency: string;
  fiatCurrencyAmount: number;
  validUntil: string;
}
