import styles from './index.module.css';
import Tabs from 'components/core/tabs';
import PageContainer from 'components/layout/page-container';
import OnExchange from './on-exchange';
import WithCard from './with-card';

import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { observer } from 'mobx-react-lite';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ROUTES } from 'routes';

const HomePage = observer(() => {
  const intl = useIntl();

  const tabsData = useMemo(
    () => [
      {
        id: 'exchange',
        text: intl.formatMessage({
          id: 'home_page.on_exchange',
          defaultMessage: 'On an exchange',
        }),
        navLink: ROUTES.index.fullPath,
      },
      {
        id: 'card',
        text: intl.formatMessage({
          id: 'home_page.with_card',
          defaultMessage: 'With card',
        }),
        navLink: ROUTES.index.card.fullPath,
      },
    ],
    [intl]
  );

  return (
    <PageContainer
      title={intl.formatMessage({
        id: 'home_page.top_up_wallet',
        defaultMessage: 'Top up EVER Wallet',
      })}
    >
      <Tabs data={tabsData} />

      <div className={styles.main}>
        <Routes>
          <Route index element={<OnExchange />} />
          <Route path={ROUTES.index.card.path} element={<WithCard />} />
          <Route
            path='*'
            element={<Navigate to={ROUTES.index.fullPath} replace />}
          />
        </Routes>
      </div>
    </PageContainer>
  );
});

export default HomePage;
