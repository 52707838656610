import styles from './index.module.css';
import classNames from 'classnames';
import ExternalLink from 'components/core/external-link';

import ArrowImg from 'assets/images/arrow.svg';
import { ReactComponent as FastestSvg } from 'assets/images/fastest.svg';

import { useIntl } from 'react-intl';
import { useMemo } from 'react';
import { AssetData } from 'stores/StaticDataStore';
import { Link } from 'react-router-dom';

const PaymentLink = ({
  data,
  internal,
}: {
  data: AssetData;
  internal?: boolean;
}) => {
  const component = useMemo(() => {
    if (!internal) {
      return (
        <ExternalLink
          className={classNames(styles.main, {
            [styles.fastest]: data.fastest === true,
          })}
          href={data.link}
        >
          <PaymentLinkContent data={data} />
        </ExternalLink>
      );
    }

    return (
      <Link
        className={classNames(styles.main, {
          [styles.fastest]: data.fastest === true,
        })}
        to={data.link}
      >
        <PaymentLinkContent data={data} />
      </Link>
    );
  }, [data, internal]);

  return component;
};

const PaymentLinkContent = ({ data }: { data: AssetData }) => {
  const intl = useIntl();

  const showWalletsAndNetworks = useMemo(
    () => !!data.walletsIcon || data.networksIcon,
    [data.networksIcon, data.walletsIcon]
  );

  return (
    <>
      <div className={styles.body}>
        <div className={styles.title}>
          <img src={data.icon} width={24} height={24} alt='' />
          <span>{data.title}</span>

          {data.fastest === true && (
            <div className={styles.label}>
              <FastestSvg />

              <span>
                {intl.formatMessage({
                  id: 'home_page.payment_link.fastest',
                  defaultMessage: 'Fastest',
                })}
              </span>
            </div>
          )}
        </div>

        {showWalletsAndNetworks && (
          <div className={styles.wallets_networks}>
            {!!data.walletsIcon && (
              <div>
                <span>
                  {intl.formatMessage({
                    id: 'home_page.payment_link.wallets',
                    defaultMessage: 'Wallets',
                  })}
                </span>

                <img src={data.walletsIcon} alt='' />
              </div>
            )}

            {!!data.networksIcon && (
              <div>
                <span>
                  {intl.formatMessage({
                    id: 'home_page.payment_link.networks',
                    defaultMessage: 'Networks',
                  })}
                </span>

                <img src={data.networksIcon} alt='' />
              </div>
            )}
          </div>
        )}

        {!!data.description && (
          <div className={styles.description}>
            {data.description.split('\\n').map((x, i) => (
              <span key={i}>
                {x}
                <br />
              </span>
            ))}
          </div>
        )}
      </div>

      <img
        className={styles.arrow}
        src={ArrowImg}
        width={20}
        height={20}
        alt=''
      />
    </>
  );
};

export default PaymentLink;
