import styles from './index.module.css';
import classNames from 'classnames';

import { ReactComponent as SpinnerSvg } from 'assets/images/spinner.svg';

type Props = {
  className?: string;
  size: 'sm' | 'md' | 'lg';
};

const Spinner = ({ className, size }: Props) => {
  return (
    <SpinnerSvg
      className={classNames(
        styles.spinner,
        { [styles.sm]: size === 'sm' },
        { [styles.md]: size === 'md' },
        { [styles.lg]: size === 'lg' },
        className
      )}
    />
  );
};

export default Spinner;
