import styles from './index.module.css';

import Spinner from 'components/core/spinner';

const LoadingWrapper = () => {
  return (
    <div className={styles.main}>
      <Spinner size='lg' />
    </div>
  );
};

export default LoadingWrapper;
