const TermsOfUse = () => {
  return (
    <div className='flex flex-col gap-8'>
      <span className='text-3xl text-center font-medium'>
        EVER Wallet End-User License Agreement
      </span>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          General provisions
        </span>

        <span>
          This User Agreement for EVER Wallet (hereinafter referred to as the
          "Agreement") regulates your use of the EVER Wallet mobile application
          and browser extension (hereinafter collectively referred to as "EVER
          Wallet") developed, operated and owned by Finstream OU (hereinafter
          the Owner of EVER Wallet), registered under the legislation of the
          Republic of Estonia at the address Harju maakond, Tallinn, Lasnamäe
          linnaosa, Katusepapi tn 4/2, 11412, Estonia, as well as services
          offered by the Owner of EVER Wallet (hereinafter referred to as the
          "Service" or "Services"). Browser extension download links, as well as
          mobile wallet applications are available on the website
          <span className='text-accent ml-1'>https://everwallet.net</span>
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          Using EVER Wallet
        </span>
        <span>
          As a user of the Services, you have a limited, personal,
          non-commercial, non-exclusive, non-transferable license. Potentially,
          the license to use EVER Wallet may be revoked in the future.
        </span>
        <span>
          We do not offer EVER Wallet to users in countries where its use or any
          transactions that may be carried out using it violate applicable law.
          However, EVER Wallet users, in turn, are also required to comply with
          some basic rules, including, among other things, the obligation never
          to use EVER Wallet for illegal purposes. If you are a resident of a
          country in which transactions that are made using EVER Wallet are
          prohibited, we ask you to immediately stop using EVER Wallet. The
          Owner of EVER Wallet is not responsible in case of a violation of this
          condition by you. You also agree to comply with all applicable laws
          and regulations when using EVER Wallet. This includes any local,
          provincial, regional/state, federal, national or international laws
          that may apply to you.
        </span>
        <span>
          You agree that you will not use the Services to pay for, support or
          otherwise engage in any illegal activity, including, but not limited
          to, illegal gambling, fraud, money laundering or terrorist activities.
        </span>
        <span>
          You also agree not to encourage a third party to engage in any of the
          aforementioned activities prohibited under this section.
        </span>
        <span>
          The Everscale blockchain differs from usual blockchain schemes. Using
          EVER Wallet and the Services, you undertake to study the principles of
          the Everscale blockchain to the extent necessary for the use of EVER
          Wallet, and independently bear all risks associated with
          misunderstanding of the blockchain, including the risk of damage due
          to errors in the use of EVER Wallet and the Everscale blockchain.
        </span>
        <span>
          Unlike other blockchains, Everscale links all wallet addresses with a
          computer program code. For the purposes of this Agreement, the
          specified code will be called a "smart contract". Keep in mine that
          each transaction is an execution of the smart contract code by the
          blockchain. In this sense, the Everscale blockchain is rather a
          distributed computing system than a registration system.
        </span>
        <span>
          Everscale uses several of its own cryptocurrencies, which together
          form a set. The basic cryptocurrency of the Everscale blockchain is
          "EVER", which is necessary for operation, since it is used to charge
          "gas fees," as described below.
        </span>
        <span>
          For any interaction with EVER Wallet, Messages are sent and delivered.
          For example, to send tokens to someone, you need to create a message,
          sign it with your cryptographic key(s), and then send it to a Smart
          contract that contains the specified tokens. The specified Smart
          Contract executes the instructions set out in the Message and
          transfers the tokens to another smart contract. All operations in
          Everscale are based on the interaction between smart contracts.
        </span>
        <span>
          By using EVER Wallet, you acknowledge and agree that EVER Wallet DOES
          NOT STORE OR TRANSFER TOKENS. EVER Wallet only transmits Messages. Any
          transfer related to any digital asset takes place on the Everscale
          blockchain, and not in any network or program owned by the Owner of
          the Software under this Agreement or any of its affiliated companies.
        </span>
        <span>
          Once you send a message via EVER Wallet, it cannot be canceled or
          otherwise changed. You need to be sure that the Smart Contract
          associated with any transaction contains enough tokens to cover all
          the necessary costs associated with the transaction before it is
          executed. You acknowledge and agree that the Owner of the EVER Wallet
          Software is not responsible for any unsuccessful transactions, except
          in cases where such a non-transaction is caused by the guilty actions
          of the Owner of the EVER Wallet. If you send a message that entails
          the commission of a transaction by the Everscale blockchain that is
          illegal or unacceptable under the rules of the blockchain or
          applicable law, or invalid or impossible for any reason (including
          insufficient tokens, etc.), such a transaction will not be completed
          by the Everscale blockchain. The owner of the EVER Wallet Software
          under this Agreement is not responsible for not making such a
          transaction.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          General provisions
        </span>

        <span>
          This User Agreement for EVER Wallet (hereinafter referred to as the
          "Agreement") regulates your use of the EVER Wallet mobile application
          and browser extension (hereinafter collectively referred to as "EVER
          Wallet") developed, operated and owned by Finstream OU (hereinafter
          the Owner of EVER Wallet), registered under the legislation of the
          Republic of Estonia at the address Harju maakond, Tallinn, Lasnamäe
          linnaosa, Katusepapi tn 4/2, 11412, Estonia, as well as services
          offered by the Owner of EVER Wallet (hereinafter referred to as the
          "Service" or "Services"). Browser extension download links, as well as
          mobile wallet applications are available on the website
          https://everwallet.net/
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          Private Keys. Master Password
        </span>
        <span>
          Private (secret) keys play a critical role in operations when it comes
          to the Everscale blockchain. They are used to sign Messages that
          interact with Smart Contracts in Everscale. THESE KEYS PROTECT ALL
          YOUR TOKENS.
        </span>
        <span>
          To use EVER Wallet, you must either import or create a private key.
          The Everscale blockchain offers a convenient way to do this through
          generation of so-called mnemonic seed phrases: a sequence of 12 or 24
          words.
        </span>
        <span>
          Upon creating or importing the private key/seed phrase you must
          provide a password that will be used to encrypt those at your device
          (hereinafter - the Master password). You may provide different Master
          passwords for different seed phrases/private keys.
        </span>
        <span>
          EVER Wallet cannot store or retrieve your key(s) or seed phrases
          unless you provide the Master password for encryption/decryption
          purposes. EVER Wallet has no way to recover your key(s) or seed
          phrases if you lose it/them or forget the Master password.
        </span>
        <span>
          You are solely responsible for storing (remembering) your private keys
          and any mnemonic (backup) phrase associated with said keys in a safe
          place from where you can retrieve them; otherwise, you risk
          permanently losing control of any tokens associated with said private
          keys. EVER Wallet does not have any means to help you recover your
          password, or private key(s), or seed phrase(s).
        </span>
      </div>

      <div className='flex flex-col'>
        <span className='text-2xl text-center font-medium mb-6'>
          Multisig wallet
        </span>
        <span>
          One of the unique features of a Multi-signature (multisig) Smart
          Contract is that it can require multiple signatures to send a message
          and provides the following additional benefits:
        </span>
        <span>
          1. Protection against key theft. Transferring funds will require a
          secondary signature(s).
        </span>
        <span>
          2. Safety of your assets, even if your security key is compromised.
        </span>
        <span>
          3. No need to transfer funds when changing your set of signatures.
        </span>
        <span>
          However, you will always remain the primary custodian of your private
          keys. You alone are responsible for their loss, misuse or disclosure.
          EVER Wallet can only help you protect your tokens and provide a
          convenient interface to operate multisig wallets. EVER Wallet is not
          responsible for the loss of your key(s). We only provide software and
          are not capable of storing actual keys themselves.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          User identification information
        </span>
        <span>
          EVER Wallet does not identify you, your device, your phone number, or
          any SIM card associated with your mobile device.
        </span>
        <span>
          If you use third party services, you are responsible for reviewing
          their terms of use before accepting them. EVER Wallet assumes no
          responsibility for your use of any third party services. You hereby
          acknowledge and agree that you will not hold EVER Wallet liable for
          any risks or losses associated with your use of any third party
          services.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          Push Notifications
        </span>
        <span>
          The push notifications provided by Google Notifications are outside of
          our direct control. Please be aware that certain third parties, such
          as mobile platform manufacturers, may view and analyze these
          notifications.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>Taxes</span>
        <span>
          You agree that you are solely responsible for determining what taxes
          may be applicable to your tokens, and properly reporting and remitting
          them in accordance with your local, state, federal and/or
          international tax laws and relevant tax authorities. You agree that
          EVER Wallet is not a qualified or licensed tax or accounting
          representative and is not responsible for determining, collecting,
          reporting, withholding, remitting or otherwise accepting any liability
          for any tax or tax information that may arise from your participation
          in and use of the Everscale Blockchain.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          Intellectual property
        </span>
        <span>
          All copyrights and other intellectual property rights in EVER Wallet,
          including materials and information published in EVER Wallet and on
          https://l1.broxus.com/freeton/wallet, including but not limited to
          design, graphics, images, the software and other related materials are
          owned solely by Finstream OU, a company incorporated under the laws of
          Estonia, whose registered office is at: Harju maakond, Tallinn,
          Lasnamäe linnaosa, Katusepapi tn 4/2, 11412, Estonia.
        </span>
        <span>
          EVER Wallet contains copyrighted materials and trademarks, including
          but not limited to text and graphics (the "Content") protected by
          copyright, registered and unregistered trademarks and other
          intellectual property rights. Unless otherwise stated, EVER Wallet is
          the sole owner of the Content. Your use of the Services does not grant
          you any ownership rights in any of the content. You may not copy,
          transfer, distribute, sell, license, create derivative works of, or
          otherwise, in whole or in part, misuse the Content.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          Third Party Services and Content
        </span>
        <span>
          In no event shall any description of, or reference to, a third party
          product or service (including, but not limited to, providing a
          description of, or linking to via a hyperlink) be construed as an
          endorsement or promotion of such third party product or service by the
          Owner of the EVER Wallet Software. EVER Wallet reserves the exclusive
          right to add or change any third-party service, as well as cancel
          access to it. If, to the extent permitted by EVER Wallet, you
          expressly allow a third party to access or connect to your Smart
          Contracts or assets through such third party product or service or
          through EVER Wallet, you hereby acknowledge and agree that allowing
          the third party to take specific actions on your behalf does not
          release you from any obligations under this Policy. You are solely
          responsible for all acts or omissions of any third party that has
          access to your Smart Contracts or tokens.
        </span>
        <span>
          You also acknowledge and agree that third parties may provide the
          ability to buy or sell certain tokens in fiat currencies or perform
          other transactions in the EVER Wallet. All your interactions with the
          aforementioned third parties and the services they provide, including
          the delivery of tokens to you, ARE NOT DECENTRALIZED and are subject
          to terms, conditions and policies other than those provided in this
          Agreement, and to which you expressly and independently agree whenever
          you enter into agreements with third parties and make purchases
          from/through them.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          Commitment to do no harm to EVER Wallet
        </span>
        <span>
          You must not distribute any viruses or other malware through EVER
          Wallet. You further agree not to take any action that could place an
          unreasonable or disproportionately large load on EVER Wallet or any of
          our third party infrastructures.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          Security Circumvention Responsibility
        </span>
        <span>
          You agree not to circumvent or attempt to circumvent any measure that
          may be in place to prevent or restrict access to EVER Wallet,
          including but not limited to other accounts, computer systems or
          networks connected to EVER Wallet. Any use of EVER Wallet, other than
          as specifically permitted in this Agreement, without prior written
          permission is strictly prohibited and will terminate your license to
          use EVER Wallet.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>
          No trackers, no cookies, no tracking, no personal data
        </span>
        <span>
          EVER Wallet does not track you or your use of the service. We fully
          comply with all globally recognized policies without the need to be
          alerted to their legal protocols because we do not collect any of your
          data. We do not track your IP address. We do not use any analytical
          software. We don't even have a simple tracking mechanism. No cookies.
          No processing of personal data. Nothing. This is our philosophy and
          our mission.
        </span>
        <span>
          Attention! As noted above, providers of other third party services
          that are outside of our control may, at their sole discretion and
          prerogative, collect and use your data. You should be very careful and
          make sure to read their privacy policy, cookie policy, terms of use
          and any other legally binding policies and documentation before
          agreeing to use their services and using EVER Wallet.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>Responsibility</span>
        <span>
          The Owner of EVER Wallet Software does not warrant that the EVER
          Wallet Software is free from bugs and/or computer viruses or
          extraneous code fragments. The Owner of EVER Wallet grants you the
          opportunity to use the EVER Wallet software "as is," without any
          warranties from the Owner of EVER Wallet. The Owner of EVER Wallet
          makes every possible effort to ensure the normal operation of EVER
          Wallet, however, is not responsible for the non-fulfillment or
          improper fulfillment of obligations under this Agreement, as well as
          any possible losses arising as a result of (but not limited to):
        </span>

        <div className='flex flex-col gap-1'>
          <span>
            ● illegal actions of other users aimed at violating the information
            security or normal functioning of the EVER Wallet and the Everscale
            blockchain;
          </span>
          <span>
            ● failures in the operation of EVER Wallet and the Everscale
            Blockchain caused by bugs in the code, computer viruses and other
            foreign code fragments in the EVER Wallet software or the Everscale
            Blockchain;
          </span>
          <span>
            ● the absence (impossibility of establishing, terminating, etc.) of
            Internet connections between the user’s server and the EVER Wallet
            server or any Everscale blockchain server;
          </span>
          <span>
            ● carrying out by state, municipal, judicial, law enforcement and
            other bodies of measures within the framework of the system of
            operational-search measures;
          </span>
          <span>
            ● establishment of state regulation (or regulation by other
            organizations) of the economic activities of commercial
            organizations on the Internet and / or the establishment by the
            specified entities of one-time restrictions that make it difficult
            or impossible to fulfill the Agreement;
          </span>
          <span>
            ● other cases related to the actions (inactions) of users and / or
            other entities aimed at worsening the general situation with the use
            of the Internet and / or computer equipment that existed at the time
            of the conclusion of the Agreement, as well as any other actions
            aimed at causing harm or difficulty of operation or introduction of
            errors into the operation of the EVER Wallet and the Everscale
            blockchain;
          </span>
          <span>
            ● performance by the Owner of EVER Wallet or by the Everscale
            Blockchain of the preventive or repair work specified in this
            Agreement.
          </span>
        </div>

        <span>
          The Owner of EVER Wallet has the right to carry out preventive and /
          or repair work on the EVER Wallet hardware and software complex with a
          temporary suspension of the EVER Wallet’s operations, if possible, at
          night and to minimize the downtime of the EVER Wallet, notifying the
          user about this, if technically it is possible.
        </span>

        <span>
          In the event of force majeure, as well as accidents or failures in the
          software and hardware systems of third parties cooperating with the
          Software Owner, or actions (inactions) of third parties aimed at
          suspending or terminating the operation of EVER Wallet or the
          Everscale blockchain, use of EVER Wallet may be suspended without
          prior notice to the user.
        </span>

        <span>
          The owner of the EVER Wallet Software is not responsible for your
          violation of this Agreement and reserves the right, at its sole
          discretion, as well as upon receipt of information from other users or
          third parties about your violation of this Agreement, to suspend,
          restrict or terminate your access to all or any sections or services
          of EVER Wallet at any time, for any reason, without explanation, with
          or without prior notice, without being responsible for any harm that
          may be caused by such action.
        </span>

        <span>
          Neither Party shall be liable for the total or partial failure to
          perform any of its obligations if the failure is the result of force
          majeure circumstances, such as, for example, flood, fire, earthquake,
          other acts of God, war, civil war or hostilities, quarantines,
          block-outs, suspension or termination of work, enactment of regulatory
          or other acts of authorized bodies that directly affect the
          fulfillment of obligations under this Agreement, and other force
          majeure circumstances that arose after the conclusion of the Agreement
          and do not depend on the will of the Parties.
        </span>
      </div>

      <div className='flex flex-col gap-6'>
        <span className='text-2xl text-center font-medium'>Personal Data</span>
        <span>
          The owner of the EVER Wallet software does not collect, store or
          process any user data. All data is stored either on the Everscale
          Blockchain, or on the user's own, or on third-party resources.
          However, if, in accordance with applicable personal data laws, by
          using EVER Wallet under this Agreement, you transfer any personal data
          to the Owner of the EVER Wallet software, you do this at your own
          risk. In this case, you also hereby confirm your consent to the
          processing of your personal data by the Owner of the EVER Wallet
          software, including the collection, systematization, accumulation,
          storage, clarification (updating, changing), use, depersonalization,
          blocking, destruction of personal data, including the collection and
          storage of biometric personal data, their verification and transfer of
          information in accordance with the degree of their compliance with the
          provided biometric personal data of the user, in order to fulfill this
          Agreement and its obligations established by applicable law by the
          Owner of the EVER Wallet Software. For more details, the Personal Data
          Protection Policy of the Owner of the EVER Wallet Software is set out
          in a separate document posted on the website
          <span className='text-accent ml-1'>https://everwallet.net</span>
        </span>
      </div>
    </div>
  );
};

export default TermsOfUse;
