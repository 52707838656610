import styles from './index.module.css';
import PaymentLink from '../payment-link';

import { useIntl } from 'react-intl';
import { observer } from 'mobx-react-lite';
import { useStaticDataStore } from 'providers/BaseStoresProvider';
import ExternalLink from 'components/core/external-link';
import { URLS } from 'assets/config';

const OnExchange = observer(() => {
  const intl = useIntl();
  const staticDataStore = useStaticDataStore();

  return (
    <div className={styles.main}>
      <div className={styles.section}>
        <div className={styles.title}>
          <span>
            {intl.formatMessage({
              id: 'home_page.dex_title',
              defaultMessage: 'Secure decentralized purchase',
            })}
          </span>

          <span>
            {intl.formatMessage({
              id: 'home_page.dex_description',
              defaultMessage: 'Identity verification is not required',
            })}
          </span>
        </div>

        <div className={styles.items}>
          {staticDataStore.dexExchanges.map((dex) => (
            <PaymentLink key={dex.title} data={dex} />
          ))}
        </div>
      </div>

      <div className={styles.section}>
        <div className={styles.title}>
          <span>
            {intl.formatMessage({
              id: 'home_page.cex_title',
              defaultMessage: 'Centralized exchanges (CEX)',
            })}
          </span>

          <span>
            {intl.formatMessage({
              id: 'home_page.cex_description',
              defaultMessage: 'Identity verification may be required',
            })}
          </span>
        </div>

        <div className={styles.items}>
          {staticDataStore.cexExchanges.map((cex) => (
            <PaymentLink key={cex.title} data={cex} />
          ))}
        </div>
      </div>

      <div className={styles.section}>
        <span className={styles.other}>
          {intl.formatMessage(
            {
              id: 'home_page.other_exchanges',
              defaultMessage:
                'Other available exchanges can be found on {coinMarketCap} or {coinGecko}',
            },
            {
              coinMarketCap: (
                <ExternalLink href={URLS.coinMarketCap}>
                  CoinMarketCap
                </ExternalLink>
              ),
              coinGecko: (
                <ExternalLink href={URLS.coinGecko}>CoinGecko</ExternalLink>
              ),
            }
          )}
        </span>
      </div>
    </div>
  );
});

export default OnExchange;
