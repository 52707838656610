export const ROUTES = {
  index: {
    path: '/*',
    fullPath: '/',
    card: {
      path: '/card',
      fullPath: '/card',
    },
  },
  simplex: {
    path: '/simplex',
    fullPath: '/simplex',
  },
  legal: {
    path: '/legal/*',
    termsOfUse: { path: '/terms-of-use', fullPath: '/legal/terms-of-use' },
    privacyPolicy: {
      path: '/privacy-policy',
      fullPath: '/legal/privacy-policy',
    },
    cookies: { path: '/cookies', fullPath: '/legal/cookies' },
  },
} as const;
