import debounce from 'lodash/debounce';

import { Api } from 'api/simplex-api';
import { makeAutoObservable, runInAction } from 'mobx';
import { WalletStore } from 'stores/WalletStore';
import { CurrencyModel } from 'models/CurrencyModel';
import {
  createPayment,
  getQuote,
  getSupportedFiatCurrencies,
} from 'repositories/SimplexRepository';
import { sanitizeNumberString } from 'utils/strings';
import { QuoteModel } from 'models/QuoteModel';
import { PaymentModel } from 'models/PaymentModel';

type FormState = {
  initResult: {
    inProgress: boolean;
    error: boolean;
  };
  loading: boolean;
  supportedCurrencies: CurrencyModel[];
  selectedCurrency: CurrencyModel | null;
  inputValues: {
    fiat: string;
    ever: string;
  };
  quote: QuoteModel | null;
  payment: PaymentModel | null;
  redirectToCheckout: boolean;
  validationError: {
    show: boolean;
    message: string;
  };
  transactionResult: {
    show: boolean;
    success: boolean;
  };
};

const INPUT_DEBOUNCE = 500;

const FORM_DEFAULT_STATE: FormState = {
  initResult: {
    inProgress: true,
    error: false,
  },
  loading: false,
  supportedCurrencies: [],
  selectedCurrency: null,
  inputValues: {
    fiat: '',
    ever: '',
  },
  quote: null,
  payment: null,
  redirectToCheckout: false,
  validationError: {
    show: false,
    message: '',
  },
  transactionResult: {
    show: false,
    success: false,
  },
};

export class SimplexPageStore {
  constructor(
    private walletStore: WalletStore,
    private simplexApi: Api<unknown>
  ) {
    makeAutoObservable(this, {}, { autoBind: true });
  }

  get state() {
    return this._formState;
  }

  get exchangeDisabled() {
    return this.state.loading || !this.state.quote;
  }

  private _formState: FormState = FORM_DEFAULT_STATE;

  private getQuoteDebounced = debounce(this.getQuote, INPUT_DEBOUNCE);

  private _lastGetQuoteRequestTimestamp = 0;

  async init(transactionResult?: 'success' | 'error') {
    if (transactionResult) {
      runInAction(() => {
        this._formState = FORM_DEFAULT_STATE;
        this._formState.transactionResult = {
          show: true,
          success: transactionResult === 'success',
        };
        this._formState.initResult = {
          inProgress: false,
          error: false,
        };
      });
      return;
    }

    try {
      runInAction(() => {
        this._formState = FORM_DEFAULT_STATE;
      });

      const supportedCurrencies = await getSupportedFiatCurrencies(
        this.simplexApi
      );

      const selectedCurrency =
        supportedCurrencies.length > 0
          ? supportedCurrencies.find((x) => x.symbol === 'USD') ??
            supportedCurrencies[0]
          : null;

      runInAction(() => {
        this._formState.supportedCurrencies = supportedCurrencies;
        this._formState.selectedCurrency = selectedCurrency;
        this._formState.initResult = { inProgress: false, error: false };
      });
    } catch (error) {
      console.error(error);

      runInAction(() => {
        this._formState.initResult = {
          inProgress: false,
          error: true,
        };
      });
    }
  }

  async exchange() {
    if (!this._formState.quote) {
      throw new Error('Cannot exchange - no current quote available.');
    }

    if (!this.walletStore.account) {
      throw new Error('Cannot exchange - wallet is not connected.');
    }

    try {
      runInAction(() => {
        this._formState.loading = true;
      });

      const payment = await createPayment(
        {
          quoteId: this._formState.quote.quoteId,
          userId: this.walletStore.userId,
          walletAddress: this.walletStore.account.address,
        },
        this.simplexApi
      );

      runInAction(() => {
        this._formState.payment = payment;
        this._formState.redirectToCheckout = true;
      });
    } catch (error) {
      console.error(error);

      runInAction(() => {
        this._formState.loading = false;
      });
    }
  }

  submitRedirectForm(form: HTMLFormElement | null) {
    if (!this._formState.payment || !this._formState.redirectToCheckout) {
      return;
    }

    form?.submit();
  }

  selectCurrency(currency: CurrencyModel) {
    const type = this._formState.inputValues.ever ? 'EVER' : 'FIAT';

    runInAction(() => {
      this._formState.selectedCurrency = currency;
      this._formState.inputValues = {
        fiat: type === 'FIAT' ? this._formState.inputValues.fiat : '',
        ever: type === 'EVER' ? this._formState.inputValues.ever : '',
      };
      this._formState.quote = null;
      this._formState.loading = true;
    });

    this.getQuoteDebounced(type);
  }

  onInputChange(value: string, type: 'EVER' | 'FIAT') {
    value = sanitizeNumberString(value);

    runInAction(() => {
      this._formState.inputValues = {
        fiat: type === 'FIAT' ? value : '',
        ever: type === 'EVER' ? value : '',
      };
      this._formState.quote = null;
      this._formState.loading = true;
    });

    this.getQuoteDebounced(type);
  }

  private async getQuote(type: 'EVER' | 'FIAT') {
    if (!this._formState.selectedCurrency) {
      throw Error('Cannot getQuote - fiat currency is not selected.');
    }

    const currentTimestamp = Date.now();

    const amount =
      type === 'FIAT'
        ? Number(this._formState.inputValues.fiat)
        : Number(this._formState.inputValues.ever);

    try {
      runInAction(() => {
        this._formState.loading = true;
        this._lastGetQuoteRequestTimestamp = currentTimestamp;
      });

      if (amount <= 0) {
        return;
      }

      const result = await getQuote(
        {
          type: type,
          amount: amount,
          userId: this.walletStore.userId,
          currency: this._formState.selectedCurrency.symbol,
        },
        this.simplexApi
      );

      if (currentTimestamp !== this._lastGetQuoteRequestTimestamp) {
        return;
      }

      runInAction(() => {
        this._formState.validationError = result.error_message
          ? { show: true, message: result.error_message }
          : { show: false, message: '' };

        this._formState.quote = result.model ?? null;

        if (!result.model) {
          return;
        }

        this._formState.inputValues = {
          fiat: result.model.fiatCurrencyAmount.toString(),
          ever: result.model.digitalCurrencyAmount.toString(),
        };
      });
    } finally {
      runInAction(() => {
        this._formState.loading = false;
      });
    }
  }
}
