import React from 'react';
import styles from './index.module.css';
import classNames from 'classnames';
import Spinner from 'components/core/spinner';

export type ButtonVariants = 'primary' | 'secondary';

type ButtonProps = {
  variant: ButtonVariants;
  showSpinner?: boolean;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ variant, className, children, showSpinner, ...buttonProps }, ref) => {
    return (
      <button
        {...buttonProps}
        className={classNames(
          styles.button,
          { [styles.primary]: variant === 'primary' },
          { [styles.secondary]: variant === 'secondary' },
          className
        )}
        ref={ref}
      >
        {showSpinner ? <Spinner size='sm' /> : children}
      </button>
    );
  }
);

export default Button;
