import styles from './index.module.css';
import classNames from 'classnames';

import SimplexLogo from 'assets/images/simplex.png';

import Button from 'components/core/button';
import ExternalLink from 'components/core/external-link';
import WarningText from 'components/core/warning-text';
import CurrencySelect from 'components/core/currency-select';
import ConnectWalletButton from 'components/core/connect-wallet-button';

import LoadingWrapper from './loading-wrapper';
import TransactionResult from './transaction-result';
import SimplexRedirectForm from './simplex-redirect-form';

import { useIntl } from 'react-intl';
import { ROUTES } from 'routes';
import { URLS } from 'assets/config';
import { Link } from 'react-router-dom';
import { useCallback, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useSimplexPageStore } from '../provider/SimplexPageStoreProvider';
import { useWalletStore } from 'providers/BaseStoresProvider';
import { cutString } from 'utils/strings';

import { CurrencyModel } from 'models/CurrencyModel';

import { ReactComponent as EverLogo } from 'assets/images/ever.svg';

const SimplexPurchaseForm = observer(() => {
  const intl = useIntl();
  const walletStore = useWalletStore();
  const simplexPageStore = useSimplexPageStore();

  const ref = useRef<HTMLFormElement>(null);

  const onExchange = useCallback(() => {
    simplexPageStore.exchange();
  }, [simplexPageStore]);

  const onSelectCurrency = useCallback(
    (currency: CurrencyModel) => {
      simplexPageStore.selectCurrency(currency);
    },
    [simplexPageStore]
  );

  const onFiatInputChange = useCallback(
    (value: string) => {
      simplexPageStore.onInputChange(value, 'FIAT');
    },
    [simplexPageStore]
  );

  const onEverInputChange = useCallback(
    (value: string) => {
      simplexPageStore.onInputChange(value, 'EVER');
    },
    [simplexPageStore]
  );

  useEffect(() => {
    if (!simplexPageStore.state.redirectToCheckout) {
      return;
    }

    simplexPageStore.submitRedirectForm(ref.current);
  }, [simplexPageStore, simplexPageStore.state.redirectToCheckout]);

  if (simplexPageStore.state.initResult.inProgress) {
    return <LoadingWrapper />;
  }

  if (simplexPageStore.state.initResult.error) {
    return (
      <TransactionResult
        success={false}
        text={intl.formatMessage({
          id: 'simplex_page.error.init',
          defaultMessage:
            'Error occured during form initialization. Please try again later.',
        })}
      />
    );
  }

  if (simplexPageStore.state.transactionResult.show) {
    return (
      <TransactionResult
        success={simplexPageStore.state.transactionResult.success}
        text={
          simplexPageStore.state.transactionResult.success
            ? intl.formatMessage({
                id: 'simplex_page.checkout.success',
                defaultMessage:
                  'Exchange operation has been completed successfully.',
              })
            : intl.formatMessage({
                id: 'simplex_page.checkout.error',
                defaultMessage:
                  'Error occurred during checkout. Please try again.',
              })
        }
        showExchangeMore
      />
    );
  }

  return (
    <div className={styles.main}>
      <div
        className={classNames(styles.value_container, styles.fiat_container)}
      >
        <span className={styles.label}>
          {intl.formatMessage({
            id: 'simplex_page.you_spend',
            defaultMessage: 'You spend',
          })}
        </span>

        <div className={styles.input_with_select}>
          <input
            className={styles.value_input}
            onChange={(event) => onFiatInputChange(event.target.value)}
            value={simplexPageStore.state.inputValues.fiat}
            placeholder='0'
          />

          <CurrencySelect
            currentSelection={simplexPageStore.state.selectedCurrency}
            availableCurrencies={simplexPageStore.state.supportedCurrencies}
            onSelect={onSelectCurrency}
          />
        </div>
      </div>

      <div
        className={classNames(styles.value_container, styles.ever_container)}
      >
        <span className={styles.label}>
          {intl.formatMessage({
            id: 'simplex_page.you_receive',
            defaultMessage: 'You receive',
          })}
        </span>

        <div className={styles.input_with_select}>
          <input
            className={styles.value_input}
            onChange={(event) => onEverInputChange(event.target.value)}
            value={simplexPageStore.state.inputValues.ever}
            placeholder='0'
          />

          <div className={styles.ever}>
            <EverLogo />

            <span>EVER</span>
          </div>
        </div>
      </div>

      {simplexPageStore.state.validationError.show && (
        <div className={styles.validation_error}>
          {simplexPageStore.state.validationError.message}
        </div>
      )}

      {walletStore.account && (
        <div className={styles.recipient}>
          <span className={styles.label}>
            {intl.formatMessage({
              id: 'simplex_page.recipient',
              defaultMessage: 'Recipient',
            })}
          </span>

          <span className={styles.address}>
            {cutString(walletStore.account.address, 8, 6)}
          </span>
        </div>
      )}

      <div className={styles.warning}>
        {walletStore.account ? (
          <WarningText
            text={intl.formatMessage({
              id: 'simplex_page.recipient.warning',
              defaultMessage:
                'Please make sure that the recipient address is correct',
            })}
          />
        ) : (
          <WarningText
            text={intl.formatMessage({
              id: 'simplex_page.wallet_not_connected.warning',
              defaultMessage: 'Please connect EVER Wallet to continue',
            })}
          />
        )}
      </div>

      <div className={styles.actions}>
        {walletStore.account ? (
          <Button
            variant='primary'
            showSpinner={simplexPageStore.state.loading}
            disabled={simplexPageStore.exchangeDisabled}
            onClick={onExchange}
          >
            {intl.formatMessage({
              id: 'simplex_page.exchange',
              defaultMessage: 'Exchange',
            })}
          </Button>
        ) : (
          <ConnectWalletButton />
        )}

        <Link to={ROUTES.index.card.fullPath}>
          <Button variant='secondary'>
            {intl.formatMessage({
              id: 'simplex_page.back',
              defaultMessage: 'Back',
            })}
          </Button>
        </Link>
      </div>

      <div className={styles.powered_by}>
        {intl.formatMessage(
          {
            id: 'simplex_page.powered_by',
            defaultMessage: 'Powered by {simplex}',
          },
          {
            simplex: (
              <ExternalLink
                className={styles.simplex_container}
                href={URLS.simplex}
              >
                <img src={SimplexLogo} width={24} height={24} alt='' />
                <span>Simplex</span>
              </ExternalLink>
            ),
          }
        )}
      </div>

      <SimplexRedirectForm ref={ref} payment={simplexPageStore.state.payment} />
    </div>
  );
});

export default SimplexPurchaseForm;
