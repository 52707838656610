import React, { useMemo } from 'react';

import { PaymentModel } from 'models/PaymentModel';
import { ROUTES } from 'routes';

type Props = {
  payment: PaymentModel | null;
};

const SimplexRedirectForm = React.forwardRef(
  ({ payment }: Props, ref: React.LegacyRef<HTMLFormElement>) => {
    const returnUrls = useMemo(
      () => ({
        success: `${window.location.origin}${ROUTES.simplex.fullPath}?result=success`,
        error: `${window.location.origin}${ROUTES.simplex.fullPath}?result=error`,
      }),
      []
    );

    return (
      <form
        action={payment?.checkoutUrl ?? ''}
        method='POST'
        target='_self'
        ref={ref}
      >
        <input type='hidden' name='version' value={payment?.version ?? ''} />
        <input type='hidden' name='partner' value={payment?.partner ?? ''} />
        <input
          type='hidden'
          name='payment_flow_type'
          value={payment?.flowType ?? ''}
        />
        <input
          type='hidden'
          name='payment_id'
          value={payment?.paymentId ?? ''}
        />
        <input
          type='hidden'
          name='return_url_success'
          value={returnUrls.success}
        />
        <input type='hidden' name='return_url_fail' value={returnUrls.error} />
      </form>
    );
  }
);

export default SimplexRedirectForm;
