import { observer, useLocalObservable } from 'mobx-react-lite';
import { createContext, useContext, useEffect } from 'react';

import { useSimplexApi, useWalletStore } from 'providers/BaseStoresProvider';
import { SimplexPageStore } from '../stores/SimplexPageStore';
import { useSearchParams } from 'react-router-dom';

const storeContext = createContext<SimplexPageStore>({} as SimplexPageStore);

type Props = {
  children: React.ReactNode;
};
export const ProvideSimplexPageStore = observer(({ children }: Props) => {
  const api = useSimplexApi();
  const wallet = useWalletStore();
  const store = useLocalObservable(() => new SimplexPageStore(wallet, api));

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const transactionResult = searchParams.get('result');

    if (!!transactionResult) {
      window.history.replaceState({}, document.title, window.location.pathname);
    }

    const init = async () => {
      await store.init(
        transactionResult === 'success' || transactionResult === 'error'
          ? transactionResult
          : undefined
      );
    };

    init();
  }, [searchParams, store]);

  return (
    <storeContext.Provider value={store}>{children}</storeContext.Provider>
  );
});

export const useSimplexPageStore = () => {
  return useContext(storeContext);
};
